import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Table } from "antd";
import promotionsActions from "services/promotion/actions";
import Column from "antd/lib/table/Column";
import CreatePromotion from "./CreatePromotion";
import DeletePromotion from "./DeletePromotion";

const PromotionsList = () => {
  const [page] = useState(1);
  const { promotionsList } = useSelector((state) => state.promotions.success);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(promotionsActions.getPromotions());
  }, [dispatch]);

  return (
    <div>
      <CreatePromotion />
      <Table sticky dataSource={promotionsList?.items}>
        <Column
          title="ID"
          dataIndex="id"
          key="id"
          className="item"
          width={120}
        />
        <Column
          title="Nombre"
          dataIndex="name"
          key="name"
          className="item-name"
        />
        <Column
          title="eliminar"
          key="dateUpload"
          className="item"
          width={120}
          render={(_, record) => {
            return <DeletePromotion promotion={record} />;
          }}
        />
      </Table>
      {promotionsList?.meta?.totalPages >= 1 && (
        <Pagination
          defaultCurrent={page}
          total={promotionsList?.meta?.totalItems}
          pageSize={10}
          // onChange={onChange}
        />
      )}
    </div>
  );
};

export default PromotionsList;
