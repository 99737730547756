/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
import { all, put, takeLatest, select } from "redux-saga/effects";
import { message } from "antd";
import Api from "../../common/api/Api";
import { parseError } from "../../common/api/ErrorParser";
import insuransersActions from "./actions";

function* getInsuransers({ payload }) {
  const filters = yield select((state) => state?.insuransers?.filters);
  const url = "/insurance-carrier/admin";
  const params = {
    page: payload.page.page || 1,
    name: payload.page.name || undefined,
    quantity: 10,
    rol: filters.rol,
  };
  if (!params.name) {
    delete params.name;
  }

  const response = yield Api.get(url, params);
  if (response.success) {
    return yield put(
      insuransersActions.getInsuransersResponse(response.detail)
    );
  }
  const errorFormatted = parseError(response);
  return yield put(insuransersActions.getInsuransersResponse(errorFormatted));
}

function* getBudgetAll({ payload }) {
  const url = "/admin/budget/all";

  const params = {
    page: payload.filters || 1,
  };
  if (!params.page) {
    delete params.page;
  }

  const response = yield Api.get(url);
  if (response.success) {
    return yield put(insuransersActions.getBudgetAllResponse(response.detail));
  }
  const errorFormatted = parseError(response);
  return yield put(insuransersActions.getBudgetAllResponse(errorFormatted));
}

function* getAdminsTermsConditions({ payload }) {
  const url = "/agent/admin/terms-conditions";

  const params = {
    page: payload.page.page || 1,
    name: payload.page.name || undefined,
    quantity: payload.page.quantity || 10,
  };
  if (!params.name) {
    delete params.name;
  }
  const response = yield Api.get(url, params);
  if (response.success) {
    return yield put(
      insuransersActions.getAdminsTermsConditionsResponse(response.detail)
    );
  }
  const errorFormatted = parseError(response);
  return yield put(
    insuransersActions.getAdminsTermsConditionsResponse(errorFormatted)
  );
}

function* getAllInsuransers() {
  const url = "/insurance-carrier/admin/verify-account";
  const response = yield Api.get(url);
  if (response.success) {
    return yield put(
      insuransersActions.getAllInsuransersResponse(response.detail)
    );
  }
  const errorFormatted = parseError(response);
  return yield put(
    insuransersActions.getAllInsuransersResponse(errorFormatted)
  );
}

function* rechargeBudget({ payload }) {
  const url = "/insurance-carrier/admin/recharge-balance";
  const response = yield Api.put(url, payload?.insuranceCarrier);
  if (response.success) {
    message.open({
      type: "success",
      content: `Recarga Exitosa!`,
    });
    return yield put(
      insuransersActions.rechargeBudgetResponse(response.detail)
    );
  }
  const errorFormatted = parseError(response);
  return yield put(insuransersActions.rechargeBudgetResponse(errorFormatted));
}

function* createInsurance({ payload }) {
  const url = "/insurance-carrier/admin";
  const response = yield Api.post(url, payload?.insurance);
  if (response.status === "SUCCESS") {
    message.open({
      type: "success",
      content: `Aseguradora creada exitosamente!`,
    });
    return yield put(
      insuransersActions.createInsuranceResponse(response.message)
    );
  }
  const errorFormatted = parseError(response);
  message.open({
    type: "error",
    content: `${response.maessage}`,
  });
  return yield put(insuransersActions.createInsuranceResponse(errorFormatted));
}

function* updateInsurance({ payload }) {
  const url = "/insurance-carrier/admin";
  const response = yield Api.put(url, payload?.emailAdmin);
  if (response.success) {
    message.open({
      type: "success",
      content: `Aseguradora actualizada exitosamente!`,
    });
    return yield put(
      insuransersActions.updateInsuranceResponse(response.detail)
    );
  }
  const errorFormatted = parseError(response);
  return yield put(insuransersActions.updateInsuranceResponse(errorFormatted));
}

function* deleteInsurance({ payload }) {
  const url = "/insurance-carrier/admin/delete";
  const response = yield Api.put(url, payload);
  if (response.success) {
    message.open({
      type: "success",
      content: `Aseguradora eliminada exitosamente!`,
    });
    return yield put(
      insuransersActions.deleteInsuranceResponse(response.detail)
    );
  }
  const errorFormatted = parseError(response);
  message.open({
    type: "error",
    content: `No se pudo eliminar debido a que actualmente tiene saldo!`,
  });
  return yield put(insuransersActions.deleteInsuranceResponse(errorFormatted));
}

function* ActionWatcher() {
  yield takeLatest(insuransersActions.getInsuransers, getInsuransers);
  yield takeLatest(insuransersActions.rechargeBudget, rechargeBudget);
  yield takeLatest(insuransersActions.updateInsurance, updateInsurance);
  yield takeLatest(insuransersActions.deleteInsurance, deleteInsurance);
  yield takeLatest(insuransersActions.createInsurance, createInsurance);
  yield takeLatest(insuransersActions.getAllInsuransers, getAllInsuransers);
  yield takeLatest(insuransersActions.getBudgetAll, getBudgetAll);
  yield takeLatest(
    insuransersActions.getAdminsTermsConditions,
    getAdminsTermsConditions
  );
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
