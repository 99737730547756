import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import promotionsActions from "services/promotion/actions";
import insuransersActions from "services/insuransers/actions";
import {
  Button,
  Checkbox,
  Input,
  InputNumber,
  Pagination,
  Select,
  Table,
} from "antd";
import Column from "antd/lib/table/Column";
import Loader from "components/loader";

const { Option } = Select;
const { TextArea } = Input;

const PromotionsForAgents = () => {
  const { promotionsList, apply } = useSelector(
    (state) => state.promotions.success
  );
  const { apply: loadingApply } = useSelector(
    (state) => state.promotions.loading
  );
  const { listAdminsTerms } = useSelector((state) => state.insuransers.success);
  const { listAdminsTerms: loading } = useSelector(
    (state) => state.insuransers.loading
  );

  const [isPromotionSelected, setPromotionSelected] = useState(0);
  const [isAmount, setAmount] = useState("");
  const [isMenssage, setMenssage] = useState("");
  const [isEmails, setEmails] = useState([]);
  const [page, setPage] = useState(1);
  const [tableKey, setTableKey] = useState(0); // Clave para forzar el re-renderizado de la tabla

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(promotionsActions.getPromotions());
    dispatch(
      insuransersActions.getAdminsTermsConditions({
        page: 1,
      })
    );
  }, []);

  const handleChange = (value) => {
    const itemSelect = promotionsList?.items?.find(
      (promotionId) => promotionId.name === value
    );
    setPromotionSelected(itemSelect);
  };

  const onChangeUser = (e, email) => {
    let newEmails = [...isEmails];
    if (e.target.checked) {
      if (!newEmails.includes(email)) {
        newEmails.push(email);
      }
    } else {
      newEmails = newEmails.filter((item) => item !== email);
    }
    setEmails(newEmails);
  };

  const onChangeAmount = (e) => {
    setAmount(e);
  };

  const onChangeMenssage = (e) => {
    setMenssage(e.target.value);
  };

  const onChangePage = (e) => {
    dispatch(
      insuransersActions.getAdminsTermsConditions({
        page: e,
      })
    );
    setPage(e);
  };
  console.log("isEmails", isEmails);

  const onFinish = () => {
    dispatch(
      promotionsActions.applyPromotion({
        promotionId: `${isPromotionSelected?.id}`,
        amount: `${isAmount}`,
        message: isMenssage,
        emails: isEmails,
      })
    );
  };

  useEffect(() => {
    if (apply) {
      setPromotionSelected(0);
      setAmount("");
      setMenssage("");
      setEmails([]);
      setPage(1);
      setTableKey((prevKey) => prevKey + 1); // Incrementar la clave para forzar el re-renderizado
    }
  }, [apply]);

  return (
    <div>
      {loading || loadingApply ? (
        <Loader />
      ) : (
        <div className="flex-table">
          <div className="left">
            <p className="body-bold">Seleccione una promoción</p>
            <Select
              onChange={(value) => handleChange(value)}
              className="select select-current"
              placeholder="Seleccione la promocion"
              showSearch
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
            >
              {promotionsList?.items?.map((item) => (
                <Option value={item.name} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
            <p className="body-bold">Cantidad</p>
            <InputNumber
              className="input-amount"
              placeholder="Cantidad"
              type="number"
              onChange={(e) => onChangeAmount(e)}
            />
            <p className="body-bold">Mensaje</p>
            <TextArea
              placeholder="Mensaje"
              onChange={(e) => onChangeMenssage(e)}
            />
            <Button
              className="button button-primary"
              onClick={() => onFinish()}
              disabled={
                isEmails?.length <= 0 ||
                isAmount === "" ||
                isAmount === null ||
                isMenssage === "" ||
                isPromotionSelected === 0
              }
            >
              Enviar
            </Button>
          </div>
          <div className="table-box">
            <Table
              sticky
              dataSource={listAdminsTerms?.items || []} // Asegúrate de que sea un array
              rowKey="id" // Usa la propiedad "id" como clave única para cada fila
              key={tableKey}
            >
              <Column
                title=""
                key="select"
                className="item"
                width={70}
                render={(_, record) => {
                  return (
                    <Checkbox
                      checked={isEmails?.includes(record?.user?.email)} // Verificar si el correo está en el estado
                      onChange={(e) => onChangeUser(e, record?.user?.email)}
                    />
                  );
                }}
              />
              <Column
                title="ID"
                dataIndex="id"
                key="id"
                className="item"
                width={100}
              />
              <Column
                title="Nombre"
                key="name"
                className="item-name"
                render={(_, record) => {
                  return <span>{record.user.person.name}</span>;
                }}
                width={200}
              />
              <Column
                title="Correo"
                key="email"
                className="item-name"
                render={(_, record) => {
                  return <span>{record.user.email}</span>;
                }}
                width={200}
              />
            </Table>
            {listAdminsTerms?.meta?.totalPages >= 1 && (
              <Pagination
                defaultCurrent={page}
                total={listAdminsTerms?.meta?.totalItems}
                pageSize={10}
                onChange={onChangePage}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PromotionsForAgents;
