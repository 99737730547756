import { Tabs } from "antd";
import React from "react";
import PromotionsForAgents from "./Tabs/promotionsForAgents/PromotionsForAgents";
import PromotionsList from "./Tabs/promotionsList/PromotionsList";

const { TabPane } = Tabs;

const Promotions = () => {
  return (
    <div className="promotions agents insuransers">
      <div className="insuransers-head">
        <h1 className="agents__title">Promociones</h1>
      </div>
      <div className="insuransers_table">
        <Tabs defaultActiveKey="promotionsList">
          <TabPane tab="Lista de promociones" key="promotionsList">
            <PromotionsList />
          </TabPane>
          <TabPane
            tab="Envio de promociones para los agentes"
            key="promotionsForAgents"
          >
            <PromotionsForAgents />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Promotions;
