/* eslint-disable import/no-cycle */
import { all, fork } from "redux-saga/effects";
import AuthSaga from "../services/auth/saga";
import AgentSaga from "../services/agents/saga";
import UserSaga from "../services/users/saga";
import LeadsSaga from "../services/leads/saga";
import ProductSaga from "../services/products/saga";
import renovationSaga from "../services/renovations/saga";
import insuransersSaga from "../services/insuransers/saga";
import offersSaga from "../services/offers/saga";
import billingSaga from "../services/billing/saga";
import promotionsSaga from "../services/promotion/saga";

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(AgentSaga),
    fork(UserSaga),
    fork(ProductSaga),
    fork(LeadsSaga),
    fork(renovationSaga),
    fork(insuransersSaga),
    fork(offersSaga),
    fork(billingSaga),
    fork(promotionsSaga),
  ]);
}
