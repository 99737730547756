import { createActions } from "redux-actions";

const { promotions } = createActions({
  PROMOTIONS: {
    GET_PROMOTIONS: () => ({}),
    GET_PROMOTIONS_RESPONSE: (promotionsList) => promotionsList,

    CREATE_PROMOTION: (promotion) => ({ promotion }),
    CREATE_PROMOTION_RESPONSE: () => ({}),

    APPLY_PROMOTION: (promotion) => ({ promotion }),
    APPLY_PROMOTION_RESPONSE: (promotion) => ({ promotion }),

    DELETE_PROMOTION: (promotion) => ({ promotion }),
    DELETE_PROMOTION_RESPONSE: (promotion) => ({ promotion }),
  },
});

export default promotions;
