import { Button, Modal, Form, Input } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import promotionsActions from "services/promotion/actions";

const CreatePromotion = () => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function onOk() {
    setIsOpen(false);
  }
  function onCancel() {
    setIsOpen(false);
  }

  const onFinish = (values) => {
    dispatch(promotionsActions.createPromotion(values));
  };
  return (
    <div className="modal">
      <div className="flex-end">
        <Button className="button button-primary" onClick={() => openModal()}>
          Crear Promoción
        </Button>
      </div>
      <Modal
        visible={modalIsOpen}
        footer={null}
        onOk={() => onOk()}
        onCancel={() => onCancel()}
        className="modal-general"
      >
        <h1 className="sub-title">Crear Promoción</h1>
        <Form onFinish={onFinish} autoComplete="off">
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor escribe el nombre de la Promoción!",
              },
            ]}
          >
            <Input placeholder="Nombre de la Promoción" />
          </Form.Item>
          <Form.Item label={null}>
            <Button className="button button-primary" htmlType="submit">
              Crear
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CreatePromotion;
