/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
import { Modal, Tabs } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import iconAgents from "../../../assets/icon/icon-agents.svg";
import { agents as AgentsActions } from "../../../services/agents/actions";
import TableApproved from "./tables/TableApproved/TableApproved";
import TablePending from "./tables/TablePending/TablePending";
import TableDenied from "./tables/TableDenied/TableDenied";
import Loader from "../../../components/loader";

const { TabPane } = Tabs;
const initialColumnOrders = {
  name: {
    column: "person.name",
    value: undefined,
  },
  createdAt: {
    column: "agents.createdAt",
    value: "ASC",
  },
  amasfac: {
    column: "association.name",
    value: undefined,
  },
};

function Agents(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [typeList, setTypeList] = useState("Active");
  const [detail, setDetail] = useState();
  const [page, setPage] = useState(1);
  const [isSearching, setIsSearching] = useState(false);
  const [quantity, setQuantity] = useState(10);
  const [filter, setFilter] = useState(null);
  const [orderColumns, setOrderColumns] = useState(initialColumnOrders);
  const [activeOrderColumns, setActiveOrderColumns] = useState("createdAt");
  const [searchValue, setSearchValue] = useState("");

  const agents = useSelector((state) => state.agents);

  const getAgents = useCallback(() => {
    dispatch(
      AgentsActions.getAgents(
        typeList,
        page,
        quantity,
        filter,
        orderColumns[activeOrderColumns]
      )
    );
  }, [
    dispatch,
    typeList,
    page,
    quantity,
    filter,
    orderColumns,
    activeOrderColumns,
  ]);

  useEffect(() => {
    if (
      !agents.loading.agents &&
      !agents.success.agents &&
      !agents.error.agents
    )
      getAgents();

    if (agents.success.changePublishPermission) getAgents();
  }, [agents, dispatch, getAgents]);

  useEffect(() => {
    setDetail(props.match.params.agentKey);
  }, [props.match.params]);

  useEffect(() => {
    getAgents();
  }, [typeList, getAgents]);

  const resetStatesByTabChange = (type) => {
    setFilter({
      column: "user.email",
      value: "",
    });
    setOrderColumns(initialColumnOrders);
    setActiveOrderColumns("createdAt");
    setTypeList(type);
    setIsSearching(false);
    setSearchValue("");
    setQuantity(10);
  };

  const openDetail = (agent) => {
    history.push(`/agents/${agent.agentKey}`);
  };

  const sort = (columnName) => {
    const newOrder = orderColumns;

    Object.keys(newOrder).map((columnkey) => {
      if (columnkey !== columnName)
        return (newOrder[columnkey].value = undefined);

      if (newOrder[columnkey].value === undefined)
        return (newOrder[columnkey].value = "ASC");

      if (newOrder[columnkey].value === "ASC")
        return (newOrder[columnkey].value = "DESC");

      if (newOrder[columnkey].value === "DESC")
        return (newOrder[columnkey].value = undefined);

      return columnkey;
    });

    setOrderColumns(newOrder);
    setActiveOrderColumns(columnName);
    dispatch(
      AgentsActions.getAgents(
        typeList,
        page,
        quantity,
        filter,
        newOrder[columnName]
      )
    );
  };

  const search = () => {
    if (!isSearching) return setIsSearching(true);

    setPage(1);
    setFilter({
      column: "user.email",
      value: searchValue,
    });
  };

  const closeDetail = () => {
    history.push(`/agents`);
    setDetail(undefined);
  };

  const acceptAgent = (agent) => {
    dispatch(AgentsActions.changePublishPermission(+agent.id, "Active"));
  };

  const cancelAgent = (agent) => {
    dispatch(AgentsActions.changePublishPermission(+agent.id, "Inactive"));
  };

  const loginAsAgent = (agent) => {
    dispatch(AgentsActions.loginAsAgent(agent));
  };

  return (
    <div className="agents">
      <h1 className="agents__title">
        <img src={iconAgents} alt="" />
        {t("label.agentsTitle")}
      </h1>
      <Modal
        className="modal-loader"
        visible={agents.loading.loginAsAgent}
        centered
        maskStyle={{ backgroundColor: "#ffffff66" }}
      >
        <Loader />
      </Modal>

      <Modal visible={!!detail} onCancel={() => closeDetail()}>
        Detail must showed
      </Modal>

      <div className="agents__tabs">
        <Tabs
          defaultActiveKey="Active"
          onChange={(key) => {
            resetStatesByTabChange(key);
          }}
        >
          <TabPane tab={t("label.agentsApproved")} key="Active">
            {agents.loading.agents && <Loader />}
            <TableApproved
              loginAsAgent={loginAsAgent}
              cancelAgent={cancelAgent}
              data={agents.success.agents?.Active}
              openDetail={openDetail}
              setPage={setPage}
              isPage={page}
              setQuantity={setQuantity}
              quantity={quantity}
              columnOrder={orderColumns}
              sort={sort}
              total={agents.success.agents?.total}
              search={search}
              isSearching={isSearching}
              setFilter={setSearchValue}
            />
          </TabPane>

          <TabPane tab={t("label.agentsPending")} key="Unchecked">
            <TablePending
              loginAsAgent={loginAsAgent}
              acceptAgent={acceptAgent}
              cancelAgent={cancelAgent}
              data={agents.success.agents?.Unchecked}
              openDetail={openDetail}
              setPage={setPage}
              setQuantity={setQuantity}
              quantity={quantity}
              columnOrder={orderColumns}
              sort={sort}
              isPage={page}
              search={search}
              isSearching={isSearching}
              total={agents.success.agents?.total}
              setFilter={setSearchValue}
            />
          </TabPane>
          <TabPane tab={t("label.agentsDenied")} key="Inactive">
            <TableDenied
              acceptAgent={acceptAgent}
              data={agents.success.agents?.Inactive}
              openDetail={openDetail}
              setPage={setPage}
              setQuantity={setQuantity}
              quantity={quantity}
              columnOrder={orderColumns}
              sort={sort}
              isPage={page}
              search={search}
              isSearching={isSearching}
              setFilter={setSearchValue}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default Agents;
