import { DeleteOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { instanceOf } from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import promotionsActions from "services/promotion/actions";

const DeletePromotion = ({ promotion }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  function openModal() {
    setIsOpen(true);
  }
  function onOk() {
    setIsOpen(false);
  }
  function onCancel() {
    setIsOpen(false);
  }

  const onFinish = (values) => {
    console.log("Success:", values);
    dispatch(promotionsActions.deletePromotion(values));
  };
  return (
    <div className="modal">
      <Button className="button button-delete" onClick={() => openModal()}>
        <DeleteOutlined />
      </Button>
      <Modal
        visible={modalIsOpen}
        footer={null}
        onOk={() => onOk()}
        onCancel={() => onCancel()}
        className="modal-general"
      >
        <h1 className="sub-title">Seguro que deseas eliminar la Promoción?</h1>
        <div className="flex">
          <Button
            className="button button-delete"
            onClick={() => onFinish(promotion.id)}
          >
            Si
          </Button>
          <Button
            className="button button-secundary"
            onClick={() => onCancel()}
          >
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
};
DeletePromotion.propTypes = {
  promotion: instanceOf(Object).isRequired,
};

export default DeletePromotion;
