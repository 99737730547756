import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  loading: {
    promotionsList: false,
    create: false,
    delete: false,
    apply: false,
  },
  error: {
    promotionsList: undefined,
    create: undefined,
    delete: undefined,
    apply: undefined,
  },
  success: {
    promotionsList: undefined,
    create: undefined,
    delete: undefined,
    apply: undefined,
  },
};

const reducer = handleActions(
  {
    PROMOTIONS: {
      GET_PROMOTIONS: (state) => ({
        ...state,
        loading: { ...state.loading, promotionsList: true },
        error: { ...state.error, promotionsList: undefined },
      }),
      GET_PROMOTIONS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, promotionsList: false },
            success: {
              ...state.success,
              promotionsList: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, promotionsList: message },
            success: { ...state.success, promotionsList: undefined },
            loading: { ...state.loading, promotionsList: false },
          };
        },
      },
      CREATE_PROMOTION: (state) => ({
        ...state,
        success: { ...state.success },
        loading: { ...state.loading, create: true },
        error: { ...state.error, create: undefined },
      }),
      CREATE_PROMOTION_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, create: false },
            success: {
              ...state.success,
              create: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, create: message },
            success: { ...state.success, create: undefined },
            loading: { ...state.loading, create: false },
          };
        },
      },
      APPLY_PROMOTION: (state) => ({
        ...state,
        success: { ...state.success },
        loading: { ...state.loading, apply: true },
        error: { ...state.error, apply: undefined },
      }),
      APPLY_PROMOTION_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, apply: false },
            success: {
              ...state.success,
              apply: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, apply: message },
            success: { ...state.success, apply: undefined },
            loading: { ...state.loading, apply: false },
          };
        },
      },
      DELETE_PROMOTION: (state) => ({
        ...state,
        success: { ...state.success },
        loading: { ...state.loading, delete: true },
        error: { ...state.error, delete: undefined },
      }),
      DELETE_PROMOTION_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, delete: false },
            success: {
              ...state.success,
              delete: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, delete: message },
            success: { ...state.success, delete: undefined },
            loading: { ...state.loading, delete: false },
          };
        },
      },
    },
  },
  INITIAL_STATE
);

export default reducer;
