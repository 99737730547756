/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
import { all, put, takeLatest } from "redux-saga/effects";
import { message } from "antd";
import Api from "../../common/api/Api";
import { parseError } from "../../common/api/ErrorParser";
import promotionsActions from "./actions";

function* getPromotions() {
  const url = "/admin/promotion";
  const response = yield Api.get(url);
  if (response.success) {
    return yield put(promotionsActions.getPromotionsResponse(response.detail));
  }
  message.error({
    content: `${response.message}`,
  });

  return yield put(
    promotionsActions.getPromotionsResponse(parseError(response))
  );
}

function* createPromotion({ payload }) {
  const url = "/admin/promotion";
  const name = payload?.promotion;

  const response = yield Api.post(url, name);
  if (response.success) {
    message.success({
      content: "La promoción ha sido creada correctamente",
    });
    yield put(promotionsActions.createPromotionResponse());
    return setTimeout(() => {
      window.location.reload();
    }, "1000");
  }
  const err = new TypeError(
    message.error({
      content: `${response.message}`,
    })
  );
  return yield put(promotionsActions.createPromotionResponse(err, response));
}

function* applyPromotion({ payload }) {
  const url = "/admin/promotion/agent";
  const params = payload?.promotion;
  const response = yield Api.post(url, params);
  if (response.success) {
    message.success({
      content: "La promoción ha sido aplicada correctamente",
    });
    return yield put(promotionsActions.applyPromotionResponse());
  }
  const err = new TypeError(
    message.error({
      content: `${response.message}`,
    })
  );
  return yield put(promotionsActions.applyPromotionResponse(err, response));
}

function* deletePromotion({ payload }) {
  const id = payload?.promotion;
  const url = `/admin/promotion/delete/${id}`;
  const response = yield Api.delete(url);
  if (response.success) {
    message.success({
      content: "La promoción ha sido eliminada correctamente",
    });
    yield put(promotionsActions.deletePromotionResponse());
    return setTimeout(() => window.location.reload(), 700);
  }
  const err = new TypeError(
    message.error({
      content: `${response.message}`,
    })
  );
  return yield put(promotionsActions.deletePromotionResponse(err, response));
}

function* ActionWatcher() {
  yield takeLatest(promotionsActions.getPromotions, getPromotions);
  yield takeLatest(promotionsActions.createPromotion, createPromotion);
  yield takeLatest(promotionsActions.deletePromotion, deletePromotion);
  yield takeLatest(promotionsActions.applyPromotion, applyPromotion);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
